.joke-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #663399;
  }
  
  .joke-button {
    margin: 20px 0;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: black;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .joke-data {
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .joke-content {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .joke-author {
    font-size: 16px;
    color: #666;
  }
  
  .joke-message {
    margin: 20px 0;
    font-size: 20px;
    font-weight: bold;
  }
  